import { PluginManifest } from "./pluginTypes";

import carescribefeManifest from "@app-manifest/care_scribe_fe";
import careabdmfeManifest from "@app-manifest/care_abdm_fe";
import carehcxfeManifest from "@app-manifest/care_hcx_fe";

export const pluginMap: PluginManifest[] = [
  carescribefeManifest,
  careabdmfeManifest,
  carehcxfeManifest
];
